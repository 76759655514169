<template>
  <div class="container-xxl">        
        <div>
            <div style="padding:20px 50px 0px 50px;"></div>
            <Createdtype ref="con1"  />
        </div>
  </div>
  
</template>
<script>
import * as moment from "moment/moment";
import Createdtype from "./Createdtype.vue" 
import { mapState } from "vuex"; 
 


export default {
  name: 'Type_control', 
  components: {
    Createdtype
  },     
  data() {
    return {
      data: [],
      actives:{page1:false,page2:true,page3:false}
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  mounted() {
    //this.fetch({results:4,page:1,pageSize: 4,pid:this.user.data.user.id });
  },
  methods: {

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
  
  },
};
</script>